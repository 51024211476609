import React from "react";
import { TerminalPrefix } from "./TerminalPrefix";
import { TerminalEvent } from "../types/types";

export type TerminalEventRowProps = TerminalEvent & {
  onClick?: () => void
}

export const TerminalEventRow: React.FC<TerminalEventRowProps> = ({ input, output, onClick }) => {
  return (
    <div className="ml-4 text-left text-terminal-grey mo" style={{ fontFamily: 'monospace' }}>
      <div className="whitespace-pre">
        <TerminalPrefix />
        {input}
      </div>
      <pre style={{ fontSize: '12px', cursor: onClick ? 'pointer' : 'default' }} onClick={onClick ? () => onClick() : undefined}>
          {output}
      </pre>
    </div>
  )
}