import { useEffect, useRef, useState } from "react";
import { TerminalContextProvider } from "../contexts/TerminalContext";
import { KeyHandlerProvider } from "../contexts/KeyHandlerContext";
import { TerminalController } from "../components/TerminalController";

export const Terminal: React.FC = () => {
  let fullText = `
                                              .o8           .   
                                              "888         .o8   
 .oooooooo  .ooooo.  oooo    ooo oooo    ooo  888oooo.  .o888oo 
888' \`88b  d88' \`88b  \`88.  .8'   \`88b..8P'   d88' \`88b   888   
888   888  888   888   \`88..8'      Y888'     888   888   888   
\`88bod8P'  888   888    \`888'     .o8"'88b    888   888   888 . 
\`8oooooo.  \`Y8bod8P'     \`8'     o88'   888o  \`Y8bod8P'   "888" 
d"     YD                                                       
"Y88888P'                                

\\\\ An interactive CLI for engaging with the govxbt agent.
\\\\ Must hold 500,000 $GOV
\\\\ Type 'help' to start.
\\\\ 
\\\\ Coming soon
`

  let phoneText = `
##    ### 
####  ##   
 ##  ##    
     ##    
 ##  ##    
####  ##   
 ##    ### 

`

  const [displayedText, setDisplayedText] = useState('');
  const [finishedDisplaying, setFinishedDisplaying] = useState(false)

  useEffect(() => {
    let index = 0;

    const intervalId = setInterval(() => {
      if (index < fullText.length - 3) {
        setDisplayedText((prev) => prev + fullText[index] + fullText[index + 1]);
        index += 2;
      } else {
        clearInterval(intervalId); // Stop the interval when all characters are added
        setFinishedDisplaying(true)
      }
    }, 1); // Adjust typing speed here

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return (
    <div className="">
      <div className="hidden phone:block">
        <div className='w-full text-[#BBBDBD] text-left' style={{ fontFamily: 'monospace' }}>
          <pre style={{ fontFamily: 'monospace', fontSize: '12px', marginLeft: '16px' }}>
            {displayedText}
          </pre>
        </div>
        <TerminalContextProvider>
          <KeyHandlerProvider shown={finishedDisplaying}>
            <TerminalController shown={finishedDisplaying}></TerminalController>
          </KeyHandlerProvider>
        </TerminalContextProvider>
      </div>
      <div className="block phone:hidden overflow-hidden mt-8">
        <span className="text-white w-full break-words" style={{ fontFamily: 'monospace', fontSize: '12px' }}>govxbt terminal is currently available only on desktop devices</span>
        <pre className="text-white w-full break-words" style={{ fontFamily: 'monospace', fontSize: '12px' }}>
          {phoneText}
        </pre>
        <span className="text-white w-full break-words" style={{ fontFamily: 'monospace', fontSize: '12px' }}>sorry ser</span>
      </div>
    </div>
  );
}