import React, { forwardRef, useContext, useEffect } from "react";
import useInputState from "../hooks/UseInputState";
import { TerminalPrefix } from "./TerminalPrefix";
import classNames from "classnames";
import { TerminalContext, useTerminalContext } from "../contexts/TerminalContext";
import { useKeyHandler } from "../contexts/KeyHandlerContext";

export const TerminalInput = forwardRef<HTMLDivElement>((props, ref) => {

  const { input, inputRef, setInput, cursorOffset, cursorOffsetRef, setCursorOffset } = useTerminalContext()
  const { setKeyHandler, removeKeyHandler } = useKeyHandler()

  useEffect(() => {
    setKeyHandler('Character', (e: KeyboardEvent) => {
      insertAtCursorPosition(e.key)
    })
    setKeyHandler('Backspace', deleteAtCursorPosition)
    setKeyHandler('ArrowRight', moveCursorRight)
    setKeyHandler('ArrowLeft', moveCursorLeft)

    return () => {
      removeKeyHandler('Character')
      removeKeyHandler('Backspace')
      removeKeyHandler('ArrowRight')
      removeKeyHandler('ArrowLeft')
    }
  }, [])

  function moveCursorRight() {
    setCursorOffset((prevOffset: number) => {
      if (prevOffset > 0) {
        return prevOffset - 1
      } else {
        return prevOffset
      }
    })
  }

  function moveCursorLeft() {
    setCursorOffset((prevOffset) => {
      if (prevOffset > inputRef.current.length - 1) {
        return prevOffset
      } else {
        return prevOffset + 1
      }
    })
  }

  function deleteAtCursorPosition() {
    if (cursorOffsetRef.current < inputRef.current.length) {
      setInput((prevInput) => {
        let index = prevInput.length - cursorOffsetRef.current
        return prevInput.slice(0, index - 1) + prevInput.slice(index)
      })
    }
  }

  function insertAtCursorPosition(char: string) {
    setInput((prevInput) => {
      let index = prevInput.length - cursorOffsetRef.current
      return prevInput.slice(0, index) + char + prevInput.slice(index)
    })
  }

  return (
    <div className="text-left ml-4 text-white -mt-1" ref={ref}>
      <TerminalPrefix />
      {input.split('').map((item, index) => (
        <span key={index} className={classNames("inline-block rounded-sm text-terminal-grey", {
          'animate-blinkBackground': input.split('').length - index == cursorOffset,
        })} style={{ fontFamily: 'monospace' }}>
          <span>{item === ' ' ? '\u00A0' : item}</span>
        </span>
      ))}
      {cursorOffset == 0 &&
        <span className="inline-block text-transparent rounded-sm" style={{ background: '#50575D', fontFamily: 'monospace', animation: 'blinkBackground 1s infinite' }}>{'\u00A0'}</span>
      }
    </div>
  )

})

export default TerminalInput