export const commandKeys = [
  'Alt',
  'Control',
  'Shift',
  'Meta',
  'CapsLock',
  'Tab',
  'Escape',
  'ArrowUp',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'Backspace',
  'Delete',
  'Insert',
  'PageUp',
  'PageDown',
  'Home',
  'Enter',
  'End',
  'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12',
  'ContextMenu',
  'Pause',
  'ScrollLock',
] as const

export function isCommandKey(k: string): boolean {
  return (commandKeys as readonly string[]).includes(k);
}

export function isCharacterKey(k: string): boolean {
  return !isCommandKey(k)
}