import React from "react";

export const TerminalPrefix: React.FC = () => {
  return (
    <span className="mr-2 text-terminal-blue font-bold" style={{ fontFamily: 'monospace' }}>
      govxbt:
      <span className="text-terminal-red">~</span>
      <span className="text-terminal-grey">$</span>
    </span>
  )
}