import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { Key } from "../types/keys";
import { isCharacterKey } from "../utils/keys";

type KeyActionHandler = (event: KeyboardEvent) => void;

interface KeyHandlerContextProps {
  setKeyHandler: (key: Key, handler: KeyActionHandler) => void;
  removeKeyHandler: (key: Key) => void;
  shown: boolean
}

export const KeyHandlerContext = createContext<KeyHandlerContextProps | undefined>(undefined)

export const KeyHandlerProvider: React.FC<{ children: React.ReactNode, shown: boolean}> = ({ children, shown }) => {
  const [keyHandlers, setKeyHandlers] = useState<Partial<Record<Key, KeyActionHandler>>>({})

  const setKeyHandler = useCallback((key: Key, handler: KeyActionHandler) => {
    setKeyHandlers((prev) => ({ ...prev, [key]: handler }))
  }, [])

  const removeKeyHandler = useCallback((key: Key) => {
    setKeyHandlers((prev) => {
      const { [key]: _, ...rest } = prev
      return rest as Record<Key, KeyActionHandler>
    });
  }, []);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {

      if (!shown) return; 

      let isCharacter = isCharacterKey(event.key)

      const charOrCmdHandler = isCharacter ? keyHandlers['Character'] : keyHandlers['Command']

      if(charOrCmdHandler) {
        //event.preventDefault()
        charOrCmdHandler(event)
      }

      const handler = keyHandlers[event.key]
      if (handler) {
        //event.preventDefault()
        handler(event)
      }
    }

    window.addEventListener("keydown", handleKeyPress)
    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }
  }, [keyHandlers, shown])

  return (
    <KeyHandlerContext.Provider value={{ setKeyHandler, removeKeyHandler, shown }}>
      {children}
    </KeyHandlerContext.Provider>
  )
}

export const useKeyHandler = (): KeyHandlerContextProps => {
  const context = useContext(KeyHandlerContext)
  if (!context) {
    throw new Error("useKeyHandler must be used within a KeyHandlerProvider")
  }
  return context
}