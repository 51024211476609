import { useEffect, useRef, useState } from "react";

export default function useInputState() {
  const [input, setInput] = useState("")
  const [cursorOffset, setCursorOffset] = useState(0)

  const inputRef = useRef(input)
  const cursorOffsetRef = useRef(cursorOffset)

  useEffect(() => {
    inputRef.current = input;
  }, [input])

  useEffect(() => {
    cursorOffsetRef.current = cursorOffset;
  }, [cursorOffset])

  return {
    input,
    setInput,
    inputRef, // Expose the ref
    cursorOffset,
    setCursorOffset,
    cursorOffsetRef, // Expose the ref
  }
}