import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { TerminalEventRow, TerminalEventRowProps } from "./TerminalEventRow";
import { TerminalInput } from "./TerminalInput";
import { useTerminalContext } from "../contexts/TerminalContext";
import { useKeyHandler } from "../contexts/KeyHandlerContext";

let helpOutput = `Utility commands:
- help (h): Show this message
- clear: Clear the terminal
- theme <dark|light>: Change the theme
- flicker <on|off>: Toggle flicker effect

Wallet management:
- connect (c): Connect your wallet (coming soon)
- disconnect (d): Disconnect your wallet (coming soon)

govxbt:
- analyze <url> (a): Analyze a governance proposal (coming soon) (must hold tokens)
- talk <message> (t): Talk to govxbt (coming soon) (must hold tokens)

Links:
- twitter (x): Get twitter link
- telegram (tg): Get telegram link
- virtuals (v): Get virtuals link
- contract (ca): Get contract address
`

export const TerminalController: React.FC<{shown: boolean}> = ({ shown }) => {

  const [terminalEvents, setTerminalEvents] = useState<TerminalEventRowProps[]>([])
  const terminalEventsRef = useRef(terminalEvents)

  const [eventIndex, setEventIndex] = useState(0)

  const { setKeyHandler, removeKeyHandler } = useKeyHandler()
  const { inputRef, setInput, setCursorOffset } = useTerminalContext()

  const containerRef = useRef<HTMLDivElement>(null);

  const changeBackgroundColor = (color: string) => {
    document.documentElement.style.setProperty("--terminal-bg", color)
  }

  const changeFlicker = (enabled: boolean) => {
    document.documentElement.style.setProperty("--terminal-animation", enabled == true ? 'flicker 0.15s infinite' : 'none')
  }

  function handleCommands(text: string) {
    let trimmed = text.trim()
    trimmed = trimmed.toLowerCase()
    let split = trimmed.split(' ')
    let command = split[0]
    let args = split.slice(1, split.length)
    
    if(command == 'clear') {
      setTerminalEvents([])
      setEventIndex(0)
      setInput('')
    } else if(command == 'help' || command == 'h') {
      if(args.length != 0) {
        addEvent('help: command does not take any argument')
      } else {
        addEvent(helpOutput)
      }
    } else if(command == 'theme') {
      if(args.length != 1 || (args[0] != 'dark' && args[0] != 'light')) {
        addEvent("theme: command requires one parameter: either 'dark' or 'light'")
      } else {
        let isDarkMode = args[0] == 'dark'
        if(isDarkMode) {
          changeBackgroundColor('#1d1f21')
        } else {
          changeBackgroundColor('#0703D6')
        }
        addEvent('')
      }
    } else if(command == 'flicker') {
      if(args.length != 1 || (args[0] != 'on' && args[0] != 'off')) {
        addEvent("flicker: command requires one parameter: either 'on' or 'off'")
      } else {
        let isEnabled = args[0] == 'on'
        if(isEnabled) {
          changeFlicker(true)
        } else {
          changeFlicker(false)
        }
        addEvent('')
      }
    } else if(command == 'connect' || command == 'c') {
      addEvent('connect: coming soon')
    } else if(command == 'disconnect' || command == 'd') {
      addEvent('disconnect: coming soon')
    } else if(command == 'analyze' || command == 'a') {
      addEvent('analyze: coming soon')
    } else if(command == 'talk' || command == 't') {
      addEvent('talk: coming soon')
    } else if(command == 'twitter' || command == 'x') {
      let xLink = 'https://x.com/gov_xbt'
      addEvent(xLink, () => {
        window.open(xLink, "_blank")
      })
    } else if(command == 'virtuals' || command == 'v') {
      let virtualsLink = 'https://app.virtuals.io/virtuals/12839'
      addEvent(virtualsLink, () => {
        window.open(virtualsLink, "_blank")
      })
    } else if(command == 'telegram' || command == 'tg') {
      let telegramLink = 'https://t.me/govxbt'
      addEvent(telegramLink, () => {
        window.open(telegramLink, "_blank")
      })
    } else if(command == 'contract' || command == 'ca') {
      let contractAddress = '0xC696003cdCE89C2f9Bd8f2183828A245E8f49D4D'
      addEvent(contractAddress, () => {
        navigator.clipboard.writeText(contractAddress)
      })
    } else {
      addEvent(`${inputRef.current}: command not found`)
    }
  }

  function addEvent(output: string, onClick?: () => void) {
    setTerminalEvents((prev) => {
      return [...prev, { input: inputRef.current, output: output, onClick: onClick }]
    })
    setInput('')
  }

  useEffect(() => {
    terminalEventsRef.current = terminalEvents

    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [terminalEvents])

  useEffect(() => {
    setKeyHandler('Enter', () => {
      handleCommands(inputRef.current)
      setCursorOffset(0)
    })

    setKeyHandler('ArrowUp', (e) => {
      e.preventDefault()
      increaseEventIndex()
    })
    setKeyHandler('ArrowDown', (e) => {
      e.preventDefault()
      decreaseEventIndex()
    })

    return () => {
      removeKeyHandler('Enter')
    }
  }, [])

  useEffect(() => {
    if(eventIndex == 0) {
      setInput('')
    } else {
      setInput(terminalEventsRef.current[terminalEventsRef.current.length - eventIndex].input)
    }
  }, [eventIndex])

  function decreaseEventIndex() {
    setEventIndex((prev) => {
      if(prev > 0) {
        return prev - 1
      }
      return prev
    })
    setCursorOffset(0)
  }

  function increaseEventIndex() {
    setEventIndex((prev) => {
      if(prev < terminalEventsRef.current.length) {
        return prev + 1
      }
      return prev
    })
    setCursorOffset(0)
  }

  return (
    <div className={classNames("mt-4", {
      'hidden': !shown,
      'block': shown
    })}>
      <div>
        {terminalEvents.map((item) => (
          <TerminalEventRow input={item.input} output={item.output} onClick={item.onClick}/>
        ))}
      </div>
        <TerminalInput ref={containerRef}></TerminalInput>
        <div className="h-4"></div>
    </div>
  )
}