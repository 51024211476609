export const NotFound: React.FC = () => {

  let fullText = `
        .o     .oooo.         .o   
    .d88    d8P'\`Y8b      .d88   
  .d'888   888    888   .d'888   
.d'  888   888    888 .d'  888   
88ooo888oo 888    888 88ooo888oo 
     888   \`88b  d88'      888   
    o888o   \`Y8bd8P'      o888o  
    
Page not found`

  return (
    <pre className="text-terminal-grey" style={{ fontFamily: 'monospace', fontSize: '12px', marginLeft: '16px' }}>
      {fullText}
      
    </pre>
  )
}