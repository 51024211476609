import { createContext, PropsWithChildren, useContext } from "react"
import useInputState from "../hooks/UseInputState"


export default interface TerminalContextProps {
  input: string
  setInput: React.Dispatch<React.SetStateAction<string>>
  inputRef: React.MutableRefObject<string>
  cursorOffset: number
  setCursorOffset: React.Dispatch<React.SetStateAction<number>>
  cursorOffsetRef: React.MutableRefObject<number>
}

export const TerminalContext = createContext<TerminalContextProps | undefined>(undefined)

export const TerminalContextProvider: React.FC<{ children: React.ReactNode}> = ({ children }) => {
  const { input, inputRef, setInput, cursorOffset, cursorOffsetRef, setCursorOffset } = useInputState()

  return (
    <TerminalContext.Provider value={{ input, setInput, inputRef, cursorOffset, setCursorOffset, cursorOffsetRef }}>
      {children}
    </TerminalContext.Provider>
  )
}

export const useTerminalContext = (): TerminalContextProps => {
  const context = useContext(TerminalContext);

  if (!context) {
    throw new Error('useTerminalContext must be used within a TerminalContextProvider');
  }

  return context;
};