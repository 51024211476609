import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Terminal } from './pages/Terminal';
import { NotFound } from './pages/NotFound';

function App() {
  return (
    <Router>
    <div className="App">
      <Routes>
        <Route path="/" element={<Terminal />} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
